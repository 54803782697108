<template>
    <div v-if="loadingState === 'loading'" class="MBcont">
        <p>Загрузка ...</p>
    </div>
    <div v-else class="container itempay">
        <div class="arsenpay">
            <iframe width=100% height="500px" :src="widgetSrc" ></iframe>
            <p>Оплачивая, вы соглашаетесь с <router-link to="/oferta">договором-оферты.</router-link></p>
        </div>
     </div>
</template>

<style>
    .itempay {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .itempay h3 {
        font: 24pt/1 'Montserrat', sans-serif;
        text-transform: initial;
        text-align: left;
        margin-bottom: 20px;
    }

    .itempay-cont {
        width: 40%;        
    }
    
    .itempay-cont2 {
        display: flex;
        justify-content: space-between;      
        align-items: center;
    }
    .itempay-cont p {
        line-height: 16pt;
    }

    .itempay-img {
        width: 35%;
        transform: scaleX(-1);
        margin-right: 15px;
    }

    .arsenpay {
        display: inline-block;
        height: auto;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        padding-bottom: 20px;
    }

    .arsenpay p{
        font-size: 11pt;
    }
</style>


<script>
    import { useRoute, useRouter } from 'vue-router'
    import { ref } from 'vue'
    import { getOrder } from "@/service/api"

    export default {
        setup() {
            const router = useRouter()
            const route = useRoute()
            const loadingState = ref("loading");
            const widgetSrc = ref('');



            const loadArsenalPay = () => {
                return getOrder(route.params.id)
                    .then(response => {
                        let order = response.data.data
                        loadingState.value = 'success'
                        let src = '/lib/arsenalpay/widget.html?'+
                            'user_id='+order.user_id+'&'+
                            'order_id='+order.order_id+'&'+
                            'amount='+order.sum+'&'+
                            'nonce='+order.nonce+'&'+
                            'sign='+order.sign+'&';
                        widgetSrc.value = src;
                    })
                    .catch(error => {
                        console.error(error.message)
                        if (error.response.status === 404) {
                            router.push("/404")
                        }
                    })
            }

            return { loadArsenalPay, widgetSrc }
        },
        created() {
            this.loadArsenalPay();
        },
    }
</script>

<style>
    
</style>